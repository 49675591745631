import React from 'react';
import { navigate } from 'gatsby';
import { useUser } from '@util/providers/AuthProvider';
import { useTranslation } from 'react-i18next';

export default function TaxExemptThankYou() {
  const { t } = useTranslation();
  const { bmoUser } = useUser();

  function finish() {
    bmoUser && bmoUser.zuoraInfo ? navigate('/') : navigateToBMO();
  }

  function navigateToBMO() {
    const extension = '/Subscription/CompleteYourProfile';
    navigate('/redirect', {
      state: {
        extension,
      },
    });
  }

  return (
    <>
      <p className="pb-2">
        {bmoUser && bmoUser.zuoraInfo
          ? t('taxExempt.yourSubmissionWasSuccessfulExisting')
          : t('taxExempt.yourSubmissionWasSuccessfulNew')}
      </p>
      <div>
        <button
          type="submit"
          className="btn btn-primary"
          id="TaxExemptFinish"
          onClick={finish}
        >
          {t('action.finish')}
        </button>
      </div>
    </>
  );
}
